import { Signal } from '../../models';
import { GET, LIST, SIGNALS, PAGINATION } from './action-types';

const endpoint = "signal";

const signalActions = {
    get: (signalId: string) => {
        return {
            types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
            payload: {
                request: {
                    method: 'GET',
                    url: `${endpoint}/${signalId}`,
                }
            }
        }
    },
    select: (signal: Signal) => {
        return {
            type: SIGNALS.SELECT,
            payload: { data: signal }
        }
    },
    unselect: () => {
        return {
            type: SIGNALS.UNSELECT
        }
    },
    list: (offset: number, limit: number) => {
        return {
            types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
            payload: {
                request: {
                    method: 'GET',
                    url: `${endpoint}/?offset=${offset}&limit=${limit}`
                }
            }
        }
    },
    changePage: (page: number) => {
        return {
            type: PAGINATION.PAGE,
            payload: {
                data: page
            }
        }
    },
    changeLimit: (limit: number) => {
        return {
            type: PAGINATION.LIMIT,
            payload: {
                data: limit
            }
        }
    }
}

export default signalActions
