/** @jsxImportSource @emotion/react */

import { Table } from "reactstrap";

import { getAbsenceStartDate, getAbsenceEndDate, getAbsenceDuration, dashIfEmpty } from "../../../../store/helpers";
import { Signal } from "../../../../store/models";
import { DATE_FORMAT_WITHOUT_CLOCK, format } from "../../../../utils/date-utils";
import { formatAbsenceDuration } from "../../../../utils/text-utils";
import { padding } from "./helpers";

export default function TypeRecoveredInformation({ signal }: { signal: Signal }) {
    return <div className="bg-panel mt-3 rounded" css={padding}>
        <h3>Verzuim samenvatting</h3>
        <Table>
            <tbody>
                <tr>
                    <td>Start verzuim</td>
                    <td className="text-center">{dashIfEmpty(getAbsenceStartDate(signal), value => format(value, DATE_FORMAT_WITHOUT_CLOCK))}</td>
                </tr>
                <tr>
                    <td>Einde verzuim</td>
                    <td className="text-center">{dashIfEmpty(getAbsenceEndDate(signal), value => format(value, DATE_FORMAT_WITHOUT_CLOCK))}</td>
                </tr>
                <tr>
                    <td>Verzuimduur</td>
                    <td className="text-center">{dashIfEmpty(getAbsenceDuration(signal), value => formatAbsenceDuration(value))}</td>
                </tr>
                <tr>
                    <td>Verwachte verzuimduur</td>
                    <td className="text-center">{signal.expected_leave_time}</td>
                </tr>
                <tr>
                    <td>Interventie voorgesteld</td>
                    <td className="text-center">{signal.intervention}</td>
                </tr>
            </tbody>
        </Table>
    </div>
}