import {
    Routes,
    Route,
} from "react-router-dom";
import Inbox from "./scenes/Inbox";
import Signal from "./scenes/Signal";
import Login from "./scenes/Login";
import Powerbi from "./scenes/Powerbi";

function MainContent() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Inbox />} />
            <Route path="/signals/:signalId" element={<Signal />} />
            <Route path="/powerbi" element={<Powerbi />} />
        </Routes>
    )
}

export default MainContent;
