/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "reactstrap";
import { AnswerOption, Question, QuestionType } from '../../../../store/models';
import { InputOption, RadioButtons } from '../../../Forms/RadioButton';
import { SingleSelect } from '../../../Forms/Select';
import questionActions from "../../../../store/actions/Question/actions";
import notifsActions from '../../../../store/actions/Notifs/actions';
import navigationActions from '../../../../store/actions/Navigation/actions';
import { RootState } from '../../../../store/reducers';
import { QuestionState } from '../../../../store/reducers/question';

type QuestionProps = {
    id: string;
    label: string;
    type: "dropdown" | "button";
    answers: AnswerOption[]
}

const padding = css`
    padding: 1em
`
export const DataTestId = {
    SAVE_BUTTON: "SAVE_BUTTON",
    QUESTION_CONTAINER: "QUESTION_CONTAINER"
}

export default (title: string, signalId: string, questions?: Question[]) => {
    const dispatch = useDispatch();
    const [answers, setAnswers] = useState<{ [x: string]: InputOption }>([] as any);

    const questionsToShow = (questions || []).filter(question => !question.required_answer || Object.values(answers).find(a => a.value === question.required_answer));
    const questionProps: QuestionProps[] = questionsToShow.map(question => ({
        ...question,
        type: question.question_type === QuestionType.DropDown ? "dropdown" : "button"
    }));

    const { isAnsweringInprogress } = useSelector<RootState, QuestionState>(state => state.question);

    const onAnswered = (question_id, option_id) => {
        const nextState = { ...answers, [question_id]: option_id }
        if (!option_id) delete nextState[question_id];
        setAnswers(nextState);
    }
    const onSave = () => {
        const unansweredMandatoryQuestions = questionsToShow.filter((question) => !answers[question.id]);

        if (unansweredMandatoryQuestions.length > 0) {
            dispatch(notifsActions.show({
                message: 'Alle verplichte vragen moeten worden beantwoord',
                type: 'warning',
                timeout: 5000
            }));
        } else {
            const answersArray = Object.entries(answers).filter(([questionId]) => questionsToShow.find(q => q.id === questionId)).map(([questionId, answer]) => ({
                question_id: questionId,
                answer_id: answer.value
            }))
            if (!isAnsweringInprogress) {
                dispatch(questionActions.answer(answersArray, signalId));
            }
        }
    }
    const onCancel = () => {
        if (!Object.keys(answers).length || confirm("Er zijn enkele veranderingen. Weet je zeker dat je annuleert?")) {
            dispatch(navigationActions.navigate("/"));
        }
    }
    return {
        title: title,
        content: <div data-testid={DataTestId.QUESTION_CONTAINER}>
            {questionProps.map(({ label, answers: options, type, id: question_id }) =>
                <div key={question_id} css={padding}>
                    <p>{label}</p>
                    {(options?.length > 0) && (type === "button")
                        ? <RadioButtons css={padding} className="mb-2 btn-block"
                            options={options.map(({ label, id, disabled }) => ({ label: label, value: id, color: "secondary", disabled: disabled }))}
                            onChange={(option) => onAnswered(question_id, option)} selectedOption={answers[question_id]} />
                        : <SingleSelect {...{ options: options.map(x => ({ label: x.label, value: x.id })), name: `select`, selectedOption: answers[question_id], onChange: (selectedOption) => onAnswered(question_id, selectedOption) }} />}
                </div>)}
        </div>,
        buttons: <div className="d-flex justify-content-end">
            <Button className="ml-2 rounded" onClick={onCancel} >Annuleren</Button>
            <Button className="ml-2 rounded" css={css`margin-left: 1em;`} onClick={onSave} data-testid={DataTestId.SAVE_BUTTON}>Opslaan</Button>
        </div>
    }
}