/** @jsxImportSource @emotion/react */
import { Buffer } from "buffer";
import { v4 as createUUID } from "uuid";
import { useDispatch} from "react-redux";
import { NOTIFS } from "../../../store/actions/Notifs/action-types";
import {  User } from "../../../store/reducers/auth";
import { Notification } from "../../../store/reducers/notifs";
import authActions from "../../../store/actions/Auth/actions";

import GoogleLogin,{GoogleLoginResponse,GoogleLoginResponseOffline} from 'react-google-login';

function parseJwt(token: string) {
    const base64Payload = token.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
}

export default function GoogleSignin() {
    const dispatch = useDispatch();
    const onFailure = (err) =>{
        const loginErrorNotification: Notification = {
            id: createUUID(),
            message: err,
            timeout: 5000,
            type: "error"
        };
        return dispatch({
            type: NOTIFS.ERROR,
            payload: { data: loginErrorNotification }
        });
    }
    const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        if ("tokenId" in res) {
            const user: User = parseJwt(res.tokenId)
            return dispatch(authActions.loginSuccess(user, res.tokenId));
        }
        return onFailure("There is no key 'tokenId' in the response from Google");
    }
    return <GoogleLogin
        clientId="894513197589-6it7otu0qvufljnca21fbhhbhdqufqpd.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
    />
}

