/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../store/actions/Auth/actions";
import { RootState } from "../../../store/reducers";
import { AuthState } from "../../../store/reducers/auth";
import { Loading } from "../../Helper/Loading";

function PowerBI() {
    const dispatch = useDispatch();
    const { powerbiEmbedInfo, isPowerbiLoading, authorizedToSeePowerBI } = useSelector<RootState, AuthState>((state) => state.auth);
    useEffect(() => {
        console.log("PRE FETCH")
        console.log(`powerbiEmbedInfo: ${powerbiEmbedInfo}`)
        console.log(`powerbiEmbedInfo is not null: ${powerbiEmbedInfo != null}`)
        console.log(`isPowerbiLoading: ${isPowerbiLoading}`)
        console.log(`powerbiEmbedInfo && powerbiEmbedInfo != null && !isPowerbiLoading: ${!powerbiEmbedInfo && powerbiEmbedInfo != null && !isPowerbiLoading}`)
        if (!powerbiEmbedInfo && powerbiEmbedInfo !== null && !isPowerbiLoading && authorizedToSeePowerBI) {
            console.log("FETCH Powerbi Embed Info")
            dispatch(authActions.getPowerBIEmbedInfo());
        }
    });
    return powerbiEmbedInfo
        ? <>
            <div css={css`width:100%;height:2.6em;background-color:white;position:relative;top:2.6em`}></div>
            <PowerBIEmbed
                embedConfig={{
                    type: "report",
                    id: powerbiEmbedInfo.reportConfig[0].reportId,
                    embedUrl: powerbiEmbedInfo.reportConfig[0].embedUrl,
                    accessToken: powerbiEmbedInfo.accessToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        layoutType: models.LayoutType.Custom,
                        customLayout: {
                            displayOption: models.DisplayOption.FitToPage,
                        },
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                    },
                }}
                getEmbeddedComponent={(embed: any) => {
                    embed.iframe.style = "height:70em; width:100%";
                }}
            /></>
        : isPowerbiLoading ? <Loading /> : <div css={css`text-align:center`}>
            <p>There is a problem. Please try again later...</p>
        </div>;
}

export default PowerBI;
