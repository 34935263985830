
export interface SickLeave {
    duration: number
    start_date: string
    end_date: string
}

export interface Insight {
    label: string,
    text: string,
    parameters: { [key: string]: string | number },
    color: string
}


export type SignalType = "SICK" | "RECOVERED" | "RISK";

export type SignalStatus = "NEW" | "PASSED_DEADLINE" | "PENDING" | "UPDATE" | "CLOSED" | "CONTACT_TEACHER" | "INTERVENTION";

export interface Signal {
    id: string,
    absence_id: string,
    type: SignalType,
    status: SignalStatus,
    employee: {
        external_id: string,
        context: {
            age: number,
            service_age: number,
            gender: string,
            fte: number,
            workdays: number,
            teacher_function: string,
            name: string
        },
        sick_leaves: SickLeave[],
        current_sick_leave: SickLeave | null;
    },
    SickLeave_id: string,
    attributes: Array<{
        key: string,
        value: string,
        created_at: string
    }>
    context: Insight[],
    assistant_message: {
        text: string;
        parameters: { [key: string]: string }
    },
    expected_leave_time: string;
    intervention: string;
}

export interface AnswerOption {
    id: string,
    label: string
    disabled: boolean;
}

export enum QuestionType { Regular, FilteredAnswers, DropDown }

export interface SelectedAnswer {
    id: string;
    created_at: string;
}

export interface Question {
    id: string;
    number: number;
    label: string;
    required_answer: string | null;
    answers: AnswerOption[];
    question_type: QuestionType;
}

export interface AnsweredQuestion extends Question {
    selected_answer: SelectedAnswer
}

export interface Feedback {
    question_id: string;
    answer_id: string;
}
