import { combineReducers } from "redux";
import authReducer, { AuthState } from "./auth";
import notifsReducer, { NotifsState } from "./notifs";
import signalReducer, { SignalState } from './signal';
import questionReducer, { QuestionState } from './question';
import navigationReducer, { NavigationState } from "./navigation";
import answerReducer, { AnswerState } from "./answer";

export interface RootState {
    navigation: NavigationState
    notifs: NotifsState
    auth: AuthState;
    signal: SignalState,
    question: QuestionState,
    answer: AnswerState
}

const rootReducer = combineReducers<RootState>({
    navigation: navigationReducer,
    notifs: notifsReducer,
    auth: authReducer,
    signal: signalReducer,
    question: questionReducer,
    answer: answerReducer
});

export default rootReducer;
