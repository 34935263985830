import { defineAction } from 'redux-define';
export const TheApp = defineAction('the-app');
export const LOADING: "LOADING" = 'LOADING';
export const ERROR: "ERROR" = 'ERROR';
export const SUCCESS: "SUCCESS" = 'SUCCESS';
export const FILTER: "FILTER" = 'FILTER';
export const STATE: "STATE" = 'STATE';
export const SELECT: "SELECT" = 'SELECT';
export const UNSELECT: "UNSELECT" = 'UNSELECT';
export const WARNING: "WARNING" = 'WARNING';
export const DISMISS: "DISMISS" = 'DISMISS';
export const SHOWN: "SHOWN" = 'SHOWN';
export const NAVIGATE: "NAVIGATE" = 'NAVIGATE';
export const REQUEST: "REQUEST" = 'REQUEST';
export const PAGE: "PAGE" = 'PAGE';
export const LIMIT: "LIMIT" = 'LIMIT';