import Select, { ActionMeta, NonceProvider } from 'react-select';
import { nonce } from "../../constants/emotionCacheProviderFactory";
import { getTheme } from "../../constants/SelectTheme";
const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? '#34495e'
          : isFocused
            ? '#2d4052'
            : null,
      color: 'white',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? '#34495e' : '#2a3b4c'),
      },
    };
  },
}

interface SingleSelectProps {
  name: string;
  options: Array<{ label: string; value: string; }>;
  selectedOption: { label: string; value: string; };
  disabled?: boolean;
  onChange?: (newValue: { label: string; value: string }, actionMeta: ActionMeta<string>) => void;
}

export const SingleSelect = (props: SingleSelectProps) => {
  return (
    <NonceProvider nonce={nonce as string} cacheKey={props.name}>
      <Select
        theme={getTheme}
        isClearable={true}
        styles={styles}
        options={props.options as any}
        value={props.selectedOption as any}
        onChange={props.onChange as any}
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        isMulti={false}
        menuPlacement={"auto"}
        isDisabled={props.disabled}
      />
    </NonceProvider>
  );
};
