import { Signal } from "../../../../store/models";
import Meta from "../Meta";
import ContextForm from "./ContextForm";
import HistoryTable from "./HistoryTable";
import StatusUpdateInformation from "./StatusUpdateInformation";
import TypeRecoveredInformation from "./TypeRecoveredInformation";

function OtherInformations({ signal }: { signal: Signal }) {
    switch (signal.type) {
        case "SICK":
            switch (signal.status) {
                case "CLOSED":
                case "NEW":
                case "PASSED_DEADLINE":
                    return <>
                        <HistoryTable leaves={signal.employee.sick_leaves} />
                        <ContextForm signal={signal} />
                    </>;
                case "PENDING":
                    return <>
                        <StatusUpdateInformation signal={signal} />
                        <ContextForm signal={signal} />
                        <HistoryTable leaves={signal.employee.sick_leaves} />
                    </>;
                case "UPDATE":
                    return <>
                        <StatusUpdateInformation signal={signal} />
                        <ContextForm signal={signal} />
                        <HistoryTable leaves={signal.employee.sick_leaves} />
                    </>;
            }
            break;
        case "RECOVERED":
            return <>
                <TypeRecoveredInformation signal={signal} />
                <ContextForm signal={signal} />
                <HistoryTable leaves={signal.employee.sick_leaves} />
            </>;
    }
    return <ContextForm signal={signal} />;
}
export default function LeftsidePanel({ signal }: { signal: Signal }) {
    return <>
        <div className={"bg-panel px-4 py-3 mt-3"}>
            <Meta signal={signal} />
        </div>
        <OtherInformations signal={signal} />
    </>
}