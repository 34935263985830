import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigationState } from '../store/reducers/navigation';
import navigationActions from '../store/actions/Navigation/actions';
import { AuthState } from '../store/reducers/auth';
import { isAuthenticated } from './utils';

const getGuardedRoutes = (path: string) => [
    path === "/",
    path?.startsWith("/signals/")
]

const getNextPath = (history: string[], path: string) => {
    const prevLocation = history[history.length - 2];
    const guardedRoutes = getGuardedRoutes(path);
    return isAuthenticated() && path === "/login" ? prevLocation || "/" :
        !isAuthenticated() && guardedRoutes.some(x => x) ? "/login" : path;
}

const NavService = () => {
    const { history, path } = useSelector<RootState, NavigationState>(state => state.navigation);
    useSelector<RootState, AuthState>(state => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const nextPath = getNextPath(history, path);

        if (nextPath !== location.pathname) {
            /**
             * state and current browser location is different, it means that some action needs to be taken
             */
            const lastLocation = history[history.length - 1];
            if (!nextPath || nextPath === lastLocation) {
                /**
                 * Browse to url while state is empty or external navigation requested through browser back , forward buttons
                 */
                dispatch(navigationActions.navigate(location.pathname));
                dispatch(navigationActions.success());
            } else {
                /**
                 * path change requested by using navigate action
                 */
                if (nextPath !== location.pathname) {
                    navigate(nextPath, { replace: false });
                    dispatch(navigationActions.success());
                }
            }
        }
    });
}

export default NavService;