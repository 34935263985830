/** @jsxImportSource @emotion/react */

import { Table } from "reactstrap";
import { dashIfEmpty, sortSickLeavesDesc } from "../../../../store/helpers";
import { SickLeave } from "../../../../store/models";
import { DATE_FORMAT_WITHOUT_CLOCK, format } from "../../../../utils/date-utils";
import { padding } from "./helpers";

interface HistoryTableProps {
    leaves: SickLeave[];
}

export default function HistoryTable({ leaves }: HistoryTableProps) {
    const sortedLeaves = sortSickLeavesDesc(leaves);
    return <div className="bg-panel mt-3 rounded" css={padding}>
        <Table>
            <thead>
                <tr>
                    <th>Startdatum</th>
                    <th>Einddatum</th>
                    <th className="text-center">Verzuimduur</th>
                </tr>
            </thead>
            <tbody>
                {sortedLeaves.map(({ start_date, end_date, duration }, index) => {
                    return <tr key={index}>
                        <td>{dashIfEmpty(start_date, value => format(new Date(value), DATE_FORMAT_WITHOUT_CLOCK))}</td>
                        <td>{dashIfEmpty(end_date, value => format(new Date(value), DATE_FORMAT_WITHOUT_CLOCK))}</td>
                        <td className="text-center">{duration === 1 ? '1 dag' : `${duration} dagen`}</td>
                    </tr>
                })}
            </tbody>
        </Table>
    </div>
}