import { defineAction } from 'redux-define';
import { LOADING, ERROR, SUCCESS, TheApp } from '../../../constants/stateConstants';
import { Question } from '../../models';
import { ReducerAction } from '../reducer-action';

export const QUESTIONS = defineAction('QUESTIONS', [], TheApp);
export const LIST = defineAction('LIST', [LOADING, ERROR, SUCCESS], QUESTIONS);
export const ANSWER = defineAction('ANSWER', [LOADING, ERROR, SUCCESS], QUESTIONS);

export type ListLoadingQuestionAction = ReducerAction<typeof LIST.LOADING>;
export type ListSuccessQuestionAction = ReducerAction<typeof LIST.SUCCESS, Question[]>;
export type ListErrorQuestionAction = ReducerAction<typeof LIST.ERROR>;
export type ListQuestionAction = ListLoadingQuestionAction | ListSuccessQuestionAction | ListErrorQuestionAction;

export type AnswerLoadingQuestionAction = ReducerAction<typeof ANSWER.LOADING>;
export type AnswerSuccessQuestionAction = ReducerAction<typeof ANSWER.SUCCESS>;
export type AnswerErrorQuestionAction = ReducerAction<typeof ANSWER.ERROR>;
export type AnswerQuestionAction = AnswerLoadingQuestionAction | AnswerSuccessQuestionAction | AnswerErrorQuestionAction;

export type QuestionAction = AnswerQuestionAction | ListQuestionAction;