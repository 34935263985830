import { isAfter } from "../utils/date-utils";
import { SIGNAL_MUTATION_DATE, SIGNAL_WORK_CAPACITY_PERCENTAGE } from "./constants";
import { AnswerOption, Signal } from "./models";

export const getAscSortedModifications = (signal: Signal) => {
    const modifications = signal.attributes.filter(attr => attr.key === SIGNAL_MUTATION_DATE);
    return modifications.sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());
}

export const getSignalCreateDate = (signal: Signal) => {
    const sortedModifications = getAscSortedModifications(signal);
    return new Date(sortedModifications[0].value);
}

export const getLastModificationDate = (signal: Signal): Date => {
    const sortedModifications = getAscSortedModifications(signal);
    return new Date(sortedModifications[sortedModifications.length - 1].value);
}

export const findAnswerByLabel = (answers: AnswerOption[], label: string): AnswerOption => {
    return answers.find(ans => ans.label === label)!;
}

export const getWorkCapacityPercentage = (signal: Signal) => {
    const wcps = signal.attributes.filter(attr => attr.key === SIGNAL_WORK_CAPACITY_PERCENTAGE);
    let latestWcp: Signal["attributes"][number] | undefined = undefined;
    for (const wcp of wcps) {
        if (!latestWcp) {
            latestWcp = wcp;
            continue;
        }
        if (isAfter(new Date(wcp.created_at), new Date(latestWcp.created_at))) {
            latestWcp = wcp;
        }
    }
    return latestWcp?.value;
}

export const dashIfEmpty = <Value>(value: Value, callback: (value: Exclude<Value, undefined | null>) => any, dash: unknown = "-") => (value ? (typeof callback === "function" && callback(<Exclude<Value, undefined | null>>value)) || value : dash);

export const getAbsenceStartDate = (signal: Signal): Date | undefined => {
    return dashIfEmpty(signal.employee.current_sick_leave, value => new Date(value.start_date), undefined);
}

export const getAbsenceEndDate = (signal: Signal): Date | undefined => {
    return dashIfEmpty(signal.employee.current_sick_leave, value => new Date(value.end_date), undefined);
}

export const getAbsenceDuration = (signal: Signal): number | undefined => {
    return dashIfEmpty(signal.employee.current_sick_leave, value => value.duration, undefined);
}

export const sortSickLeavesDesc = (sick_leaves: Signal["employee"]["sick_leaves"]) => {
    return sick_leaves.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());
}