import { defineAction } from 'redux-define';
import { LOADING, ERROR, SUCCESS, TheApp, SELECT } from '../../../constants/stateConstants';
import { AnsweredQuestion } from '../../models';
import { ReducerAction } from '../reducer-action';

export const ANSWERS = defineAction('ANSWERS', [LOADING, ERROR, SUCCESS, SELECT], TheApp);
export const LIST = defineAction('LIST', [LOADING, ERROR, SUCCESS], ANSWERS);

export type ListLoadingAnswerAction = ReducerAction<typeof LIST.LOADING>;
export type ListSuccessAnswerAction = ReducerAction<typeof LIST.SUCCESS, { [date: string]: AnsweredQuestion[] }>;
export type ListErrorAnswerAction = ReducerAction<typeof LIST.ERROR>;
export type ListAnswerAction = ListLoadingAnswerAction | ListSuccessAnswerAction | ListErrorAnswerAction;

export type AnswerAction = ListAnswerAction;