import { NOTIFS } from './action-types';
import { Notification } from "../../reducers/notifs";
import { v4 as createUUID } from 'uuid';

type NotificationData = Omit<Notification, "id">

const createNotification = (notification: NotificationData): Notification => {
    return {
        ...notification,
        id: createUUID()
    }
}

const notifsActions = {
    show: (data: NotificationData) => {
        return {
            type: NOTIFS.WARNING,
            payload: { data: createNotification(data) }
        }
    },
    dismiss: (notificationId: string) => {
        return {
            type: NOTIFS.DISMISS,
            payload: { data: { notificationId } }
        }
    },
    shown: (notificationId: string) => {
        return {
            type: NOTIFS.SHOWN,
            payload: { data: { notificationId } }
        }
    }
}

export default notifsActions
