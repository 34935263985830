import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { render } from 'react-dom';
import reportWebVitals from './reportWebVitals'
import store from "./store";
import { emotionCacheProviderFactory } from "./constants/emotionCacheProviderFactory";
import App from './components/App';

// Stylesheet
import "./styles/style.scss";
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById('root')!;
export const DefaultCacheProvider = emotionCacheProviderFactory(document.getElementById('EmotionStyleContainer'));
render(
  <DefaultCacheProvider>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </DefaultCacheProvider>,
  rootElement
  );

reportWebVitals();
