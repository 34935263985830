import { LIST } from './action-types';

const endpoint = "feedback/answered_grouped";

const signalActions = {
    list: (signalId: string) => {
        return {
            types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
            payload: {
                request: {
                    method: 'GET',
                    url: `${endpoint}/${signalId}`,
                }
            }
        }
    }
}

export default signalActions
