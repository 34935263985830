/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const Loading = () => (
  <div className="container-fluid">
    <div className="row justify-content-center py-5 opacity-80">
      <div className="spinner-border" role="status">
          <span className="sr-only"></span>
      </div>
    </div>
</div>
)

export const LoadingOverlay = () =>
  <div css={css`position: absolute; width: 90%; height: 100%;`}>
    <div css={css`position: absolute; top: 5%; left:45%`}><Loading /></div>
  </div>



