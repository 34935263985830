import { defineAction } from "redux-define";
import { LOADING, ERROR, SUCCESS, TheApp, SELECT, UNSELECT, PAGE, LIMIT } from "../../../constants/stateConstants";
import { Signal } from "../../models";
import { ReducerAction } from "../reducer-action";

export const SIGNALS = defineAction("SIGNALS", [SELECT, UNSELECT], TheApp);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], SIGNALS);
export const LIST = defineAction("LIST", [LOADING, ERROR, SUCCESS], SIGNALS);
export const PAGINATION = defineAction("PAGINATION", [PAGE, LIMIT], SIGNALS);

export type SelectSignalAction = ReducerAction<typeof SIGNALS.SELECT, Signal>;
export type UnselectSignalAction = ReducerAction<typeof SIGNALS.UNSELECT>;

export type GetLoadingSignalAction = ReducerAction<typeof GET.LOADING>;
export type GetSuccessSignalAction = ReducerAction<typeof GET.SUCCESS, Signal>;
export type GetErrorSignalAction = ReducerAction<typeof GET.ERROR>;
export type GetSignalAction = GetLoadingSignalAction | GetSuccessSignalAction | GetErrorSignalAction;

export type ListLoadingSignalAction = ReducerAction<typeof LIST.LOADING>;
export type ListSuccessSignalAction = ReducerAction<typeof LIST.SUCCESS, { results: Signal[], count: number }>;
export type ListErrorSignalAction = ReducerAction<typeof LIST.ERROR>;
export type ListSignalAction = ListLoadingSignalAction | ListSuccessSignalAction | ListErrorSignalAction;

export type ChangePageAction = ReducerAction<typeof PAGINATION.PAGE, number>;
export type ChangeLimitAction = ReducerAction<typeof PAGINATION.LIMIT, number>;

export type SignalAction = SelectSignalAction | UnselectSignalAction | GetSignalAction | ListSignalAction | ChangePageAction | ChangeLimitAction;
