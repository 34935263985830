import axios from "axios";

const apiClient = () => {
  const defaultOptions: any = {
    method: "get",
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true,
    credentials: "include",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN"
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("TOKEN");
    config.headers.Authorization = token ? `${token}` : "";
    return config;
  });

  return instance;
};

export default apiClient();

