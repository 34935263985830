/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AnsweredQuestion, AnswerOption, QuestionType } from '../../../../store/models';
import { InputOption, RadioButtons } from '../../../Forms/RadioButton';
import { SingleSelect } from '../../../Forms/Select';

type AnsweredQuestionProps = {
    id: string;
    label: string;
    type: "dropdown" | "button";
    answers: AnswerOption[]
}

const padding = css`
    padding: 1em
`
export const DataTestId = {
    SAVE_BUTTON: "SAVE_BUTTON",
    QUESTION_CONTAINER: "QUESTION_CONTAINER"
}

export default (title: string, questions: AnsweredQuestion[]) => {
    const answers = {} as { [questionId: string]: InputOption };
    for (const question of questions) {
        const selected_answer = question.answers.find(x => x.id === question.selected_answer.id);
        if (!selected_answer) throw "Data mismatch: Selected answer should be one of the answers: Backend error";
        answers[question.id] = {
            label: selected_answer.label,
            value: selected_answer.id,
            disabled: false
        };
    }
    const questionsToShow = (questions || []).filter(question => !question.required_answer || Object.values(answers).find(a => a.value === question.required_answer));
    const questionProps: AnsweredQuestionProps[] = questionsToShow.map(question => ({
        ...question,
        type: question.question_type === QuestionType.DropDown ? "dropdown" : "button"
    }));
    return {
        title: title,
        content: <div data-testid={DataTestId.QUESTION_CONTAINER}>
            {questionProps.map(({ label, answers: options, type, id: question_id }) =>
                <div key={question_id} css={padding}>
                    <p>{label}</p>
                    {(options?.length > 0) && (type === "button")
                        ? <RadioButtons css={padding} className="mb-2 btn-block" disabled={true}
                            options={options.map(({ label, id, disabled }) => ({ label: label, value: id, color: "secondary", disabled: disabled }))} selectedOption={answers[question_id]} />
                        : <SingleSelect {...{ options: options.map(x => ({ label: x.label, value: x.id })), name: `select`, selectedOption: answers[question_id] }} />}
                </div>)}
        </div>,
        buttons: <></>
    }
}