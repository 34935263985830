/** @jsxImportSource @emotion/react */
import { Link, NavLink } from "react-router-dom";
import { css } from '@emotion/react';
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import logo from '../img/logo.png';
import authActions from '../store/actions/Auth/actions';
import navigationActions from '../store/actions/Navigation/actions';
import { RootState } from "../store/reducers";
import { AuthState } from "../store/reducers/auth";


export function getNavLinks(authorizedToSeePowerBI?: boolean) {

  const links = [
    <li className="nav-item" key="inbox">
      <NavLink className="nav-link text-white header-item" to="/"><p>Inbox</p></NavLink>
    </li>,
    // <li className="nav-item" key="powerbi">
    //   <NavLink className="nav-link text-white header-item" to="/powerbi"><p>Archief</p></NavLink>
    // </li>
  ]
  if (authorizedToSeePowerBI) {
    links.push(<li className="nav-item" key="powerbi">
      <NavLink className="nav-link text-white header-item" to="/powerbi"><p>Dashboard</p></NavLink>
    </li>,)
  }
  return links;
}


const logoutButtonStyle = css`
  background: none!important;
  border: none!important;
  padding: 0!important;
  color: white!important;
  text-decoration: underline!important;
`

export default function Header() {

  const { user, authorizedToSeePowerBI } = useSelector<RootState, AuthState>(state => state.auth);
  const dispatch = useDispatch();
  const onClickLogout = () => {
    dispatch(authActions.logoutRequest());
  }
  const onClickAvatar = () => {
    dispatch(navigationActions.navigate("/"));
  }
  return (
    <nav className="navbar navbar-expand-sm navbar-dark static-top px-0">
      <div className="container">
        <div className="navbar-brand" css={css`cursor:pointer`} onClick={onClickAvatar}>
          <Link to="/" >
            <img src={logo} alt="MADI" title="MADI" width="80px" height="80px" />
          </Link>
        </div>
        <div className="w-75">
          <div className="navbar">
            <ul className="navbar-nav">
              <>
                {getNavLinks(authorizedToSeePowerBI)}
              </>
            </ul>
          </div>
        </div>
        <div className="w-25">
          {
            user &&
            <>
              <div className="text-end">Logged in as &nbsp;<b>{user.given_name}</b><>{` (${user.email})`}</>&nbsp;</div>
              <div className="text-end"><Button css={logoutButtonStyle} onClick={() => onClickLogout()}>Logout</Button></div>
              <div> </div>
            </>
          }
        </div>
      </div>
    </nav>)
}
