/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Col, Container, Row } from "reactstrap";
import GoogleSignin from "./GoogleSignIn";

export default function Login() {

    return <Container>
        <Row className="align-items-center" css={css`height: 100vh`}>
            <Col className="text-center">
                <h2 className="mb-3">Login</h2>
                <p className="lead">Welkom bij MADI!<br></br>Gebruik je Google account om in te loggen.</p>
                <div className="mx-auto text-center">
                    <GoogleSignin/>
                </div>
            </Col>
        </Row>
    </Container>
}
