/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import smile from '../img/logo.png';
import { Loading } from './Helper/Loading';
import TabbedContainer, { Tab } from './Helper/TabbedContainer';


export type Pane = {
    title: string;
    content: JSX.Element;
    buttons: JSX.Element;
}

export type AssistantAvatarProps = {
    text: JSX.Element;
    emotion?: "smile",
    panes: Pane[],
    isLoading?: boolean,
    selectedPane: number,
    onPaneChange: (selectedPane: number) => void
}

const avatars = {
    smile
}

export const DataTestId = {
    AVATAR_SPEECH_BALOON: "AVATAR_SPEECH_BALOON"
}

export default function AssistantAvatar({ text, emotion = "smile", panes, isLoading, selectedPane, onPaneChange }: AssistantAvatarProps) {
    const tabs: Tab[] = panes.map(({ title, content, buttons }) => ({
        title: title,
        content: <>
            <div className="p-3 bg-panel">
                {content}
            </div>
            <div css={css`margin-top:1em;margin-bottom:1em`}>
                {buttons}
            </div>
        </>,
    }))

    return (
        <div>
            <div className="d-flex" css={css`padding-left:1.6em`}>
                <div className="flex-grow-1 ">
                    <div className="pt-3">
                        <div css={speachBubbleCSS} className="p-3" data-testid={DataTestId.AVATAR_SPEECH_BALOON}>
                            {text}
                        </div>
                    </div>
                </div>
                <div css={css`width: 200px`} className="pt-3" >
                    <img className="w-100" src={avatars[emotion]} alt="Avatar" />
                </div>
            </div >
            <div>
                {isLoading ? <Loading /> : (<TabbedContainer tabs={tabs} activeTab={selectedPane} onTabChange={onPaneChange} />)}
            </div>
        </div>
    )
}

const speachBubbleCSS = css`
	position: relative;
	background: #008888;
    border-radius: .4em;
    min-height: 5rem;
    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 40%;
        width: 0;
        height: 0;
        border: 16px solid transparent;
        border-left-color: #008888;
        border-right: 0;
        margin-top: -16px;
        margin-right: -16px;
    }`;