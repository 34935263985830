import { Action } from "../actions";
import { NAVIGATION } from "../actions/Navigation/action-types";


export interface NavigationState {
    path: string;
    history: string[];
}

const defaultState: NavigationState = {
    path: window.location.pathname,
    history: [window.location.pathname]
}

export default function Reducer(state: NavigationState = defaultState, action: Action): NavigationState {
    switch (action.type) {
        case NAVIGATION.NAVIGATE:
            return { ...state, path: action.payload.data.path }
        case NAVIGATION.SUCCESS:
            return {...state, history:[...state.history,state.path!]}
    }
    return state;
}
