import { Badge } from "reactstrap";
import { SignalType } from "../../store/models";

const englishToDutchTypeMaper = (type: string) => {
  switch(type) {
    case "SICK":
      return "ZIEK";
    case "RECOVERED":
      return "BETER";
    case "RISK":
      return "RISICO";
    default:
      return "-";
  }
}

function TypeBadge({ type }: TypeBadgeProps) {
  const classname = `signal-type ${(type).toLowerCase()} py-2 px-3`;
  return (
    <Badge className={classname}>{englishToDutchTypeMaper(type)}</Badge>
  )
}
interface TypeBadgeProps {
  type: SignalType;
}

export default TypeBadge;