import { Action } from '../actions';
import { LIST, SIGNALS, GET, PAGINATION, } from '../actions/Signal/action-types';
import { ANSWER } from "../actions/Question/action-types";
import { Signal } from '../models';
import { LOGOUT } from '../actions/Auth/action-types';
import { NAVIGATION } from '../actions/Navigation/action-types';

export interface SignalState {
    isItemsLoading: boolean;
    isCurrentLoading: boolean;
    items?: Signal[];
    current?: Signal | null;
    pagination: {
        totalPages: number,
        limit: number,
        currentPage: number
    }
}

const defaultState: SignalState = {
    isItemsLoading: false,
    isCurrentLoading: false,
    current: undefined,
    pagination: {
        currentPage: 1,
        limit: 12,
        totalPages: 0,
    }
};

const reducer = (state: SignalState = defaultState, action: Action): SignalState => {
    switch (action.type) {
        case SIGNALS.SELECT:
            return { ...state, current: action.payload.data };
        case SIGNALS.UNSELECT:
            return { ...state, current: undefined };
        case LIST.LOADING:
            return { ...state, isItemsLoading: true };
        case GET.LOADING:
            return { ...state, isCurrentLoading: true };
        case LIST.SUCCESS:
            return { ...state, isItemsLoading: false, items: action.payload.data.results, pagination: { ...state.pagination, totalPages: action.payload.data.count } };
        case GET.SUCCESS:
            return { ...state, isCurrentLoading: false, current: action.payload.data };
        case LIST.ERROR:
            return { ...state, isItemsLoading: false, items: [] };
        case GET.ERROR:
            return { ...state, isCurrentLoading: false, current: null };
        case ANSWER.SUCCESS:
            return { ...state, current: undefined, items: undefined };
        case LOGOUT.SUCCESS:
            return defaultState;
        case NAVIGATION.NAVIGATE:
            return { ...state, items: action.payload.data.path === "/" ? undefined : state.items, current: action.payload.data.path.startsWith("/signals/") ? undefined : state.current }
        case PAGINATION.PAGE:
            return { ...state, items: undefined, pagination: { ...state.pagination, currentPage: action.payload.data } }
        case PAGINATION.LIMIT:
            return { ...state, items: undefined, pagination: { ...state.pagination, limit: action.payload.data } }
        default:
            return state;
    }
}

export default reducer;