/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Col, Container, Row} from "reactstrap";
import { Signal } from "../../../../store/models";
import { ParameterizedText } from "../Helpers";

interface ContextFormProps {
    signal: Signal
}

const COL_COUNT_PER_ROW = 1;
const Title = ({ children }) => <b><u>{children}</u></b>;
const createContextCellStyle = (color: string) => css`border: 0.4em solid ${color}; padding:1em; margin:1em; min-width:10em;`;

export default function ContextForm({ signal }: ContextFormProps) {
    const insights = signal.context;
    const rowCount = Math.ceil(Number(insights.length) / COL_COUNT_PER_ROW);
    const getSlice = (rowIndex: number) => insights.slice(rowIndex * COL_COUNT_PER_ROW, (rowIndex + 1) * COL_COUNT_PER_ROW);
    return (
        <Container className="bg-panel px-4 pt-3 mt-3 rounded">
            {
                Array.from({ length: rowCount }, (_, rowIndex) =>
                    <Row key={rowIndex}>
                        {
                            getSlice(rowIndex).map(({ text, color, label, parameters }, index) =>
                                <Col key={index} css={createContextCellStyle(color)} className="rounded">
                                    <div className="pb-1">
                                        <Title>{label}</Title>
                                    </div>
                                    <div>
                                        <ParameterizedText text={text} parameters={parameters} />
                                    </div>
                                </Col>)
                        }
                    </Row>)
            }
        </Container>
    )
}
