/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from "reactstrap";

const buttonCSS = css`
    margin-right: 1em;
`;

export function RadioButton({ value, outline, color, className, onChange, children, disabled }) {
    return (
        <Button color={color} outline={outline} disabled={disabled} style={{ borderRadius: "0.5em" }} css={buttonCSS} className={['rounded', 'btn-radio', className].join(' ')} onClick={() => onChange(value)}>
            {children}
        </Button>
    );
}

RadioButton.defaultProps = {
    color: 'primary',
    disabled: false
}

export interface RadioButtonProps {
    options: InputOption[];
    selectedOption: InputOption | undefined;
    color: string;
    onChange?: (option: InputOption) => void;
    className: string;
    disabled?: boolean;
}

export interface InputOption {
    label: string;
    value: string;
    color?: string;
    className?: string;
    disabled: boolean;
}

export function RadioButtons({ options, selectedOption, color, onChange, className, disabled }: RadioButtonProps) {
    return <> {
        options.map(option => option && <b key={option.value}>
            <RadioButton disabled={disabled || option.disabled} color={option.color ? option.color : color} value={option.value} outline={selectedOption?.value !== option.value} className={option.className + ' ' + className} onChange={(value) => onChange && onChange({ ...option, value })} >{option.label}</RadioButton>
            {""}
        </b>)
    } </>
}


RadioButtons.defaultProps = {
    color: 'primary',
    className: 'mr-1',
    disabled: false
}