import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import notifsActions from '../store/actions/Notifs/actions';
import { RootState } from '../store/reducers';
import { NotifsState } from '../store/reducers/notifs';

const getNotificationsToShow = ({ notifsToShow, allNotifs }: NotifsState) =>
    allNotifs.filter(({ id }) => notifsToShow.includes(id))

const getStyle = (type: string) => {
    const returnValue: any = {
        style: {
            borderRadius: "0.5em",
        },
        bodyStyle: {
            display: "flex",
            justifyContent: "center"
        }
    }
    switch (type) {
        case "success":
            returnValue.style = {
                ...returnValue.style,
                backgroundColor: "green"
            };
            break;
    }
    return returnValue;
};
export const createPopup = (message: string, type: "warning" | "error" | "success") => {
    return toast[type](message, getStyle(type));
}

const NotifsService = () => {
    const notifsState = useSelector<RootState, NotifsState>(state => state.notifs);
    const dispatch = useDispatch();
    for (const { id, type, message, timeout } of getNotificationsToShow(notifsState)) {
        const popup = createPopup(message, type);
        dispatch(notifsActions.shown(id));
        setTimeout(() => {
            toast.dismiss(popup);
            dispatch(notifsActions.dismiss(id));
        }, timeout);
    }
}

export default NotifsService;