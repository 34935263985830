import 'dayjs/locale/nl';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.locale('nl')
dayjs.extend(customParseFormat)

export const FULL_DATE_FORMAT = "DD-MM-YYYY HH:MM";
export const DATE_FORMAT_WITHOUT_CLOCK = "DD-MM-YYYY";

const firstDate = dayjs(new Date());
export const format = (date: Date, format: string = FULL_DATE_FORMAT) => {
    const dayjsDate = dayjs(date);
    return firstDate.isSame(dayjsDate) ? "-" : dayjsDate.format(format)
}
export const isAfter = (dateFirst: Date, dateSecond: Date) => {
    return dayjs(dateFirst).isAfter(dateSecond);
}