import { User } from "../../reducers/auth";
import { LOGIN, LOGOUT, POWERBI_AUTH_GET, POWERBI_GET } from './action-types';

const authActions = {
  loginLoading: () => ({
    type: LOGIN.LOADING
  }),
  loginSuccess: (user: User, token: string) => ({
    type: LOGIN.SUCCESS,
    payload: { data: { user, token } }
  }),
  loginError: (message: string) => ({
    type: LOGIN.ERROR,
    payload: { data: { message } }
  }),
  logoutRequest: () => ({
    type: LOGOUT.REQUEST
  }),
  logoutLoading: () => ({
    type: LOGOUT.LOADING
  }),
  logoutSuccess: () => ({
    type: LOGOUT.SUCCESS
  }),
  logoutError: (message: string) => ({
    type: LOGOUT.ERROR,
    payload: { data: { message } }
  }),
  getPowerBIEmbedInfo() {
    return {
      types: [POWERBI_GET.LOADING, POWERBI_GET.SUCCESS, POWERBI_GET.ERROR],
      payload: {
        request: {
          method: "GET",
          url: "powerbi/getembedinfo",
        },
      },
    };
  },
  getIfAuthorizedToSeePowerBI() {
    return {
      types: [POWERBI_AUTH_GET.LOADING, POWERBI_AUTH_GET.SUCCESS, POWERBI_AUTH_GET.ERROR],
      payload: {
        request: {
          method: "GET",
          url: "powerbi/access_check/general",
        },
      },
    };
  }
}

export default authActions;
