import { NAVIGATION } from './action-types';

const navigationActions = {
    navigate: (path: string) => {
        return {
            type: NAVIGATION.NAVIGATE,
            payload: { data: { path } }
        }
    },
    success:() =>{
        return {
            type: NAVIGATION.SUCCESS,
        }
    }
}

export default navigationActions
