import { v4 as createUUID } from 'uuid';
import { Action } from '../actions';
import { LOGOUT } from '../actions/Auth/action-types';
import { NOTIFS } from '../actions/Notifs/action-types';
import { ANSWER } from '../actions/Question/action-types';

export interface Notification {
    id: string;
    message: string,
    type: "warning" | "error" | "success",
    timeout: number
}

export interface NotifsState {
    allNotifs: Notification[];
    notifsToShow: string[];
}

const defaultState: NotifsState = {
    allNotifs: [],
    notifsToShow: []
}

export default function Reducer(state: NotifsState = defaultState, action: Action): NotifsState {
    switch (action.type) {
        case NOTIFS.ERROR:
        case NOTIFS.WARNING:
            return { ...state, allNotifs: [...state.allNotifs, action.payload.data], notifsToShow: [...state.notifsToShow, action.payload.data.id] }
        case NOTIFS.DISMISS:
            return { ...state, allNotifs: state.allNotifs.filter(({ id }) => id !== action.payload.data.notificationId) }
        case NOTIFS.SHOWN:
            return { ...state, notifsToShow: state.notifsToShow.filter(id => id !== action.payload.data.notificationId) }
        case LOGOUT.SUCCESS:
            return defaultState;
        case ANSWER.SUCCESS:
            const successNotifId = createUUID();
            const successNotif: Notification = {
                id: successNotifId,
                message: "Answers are successfully saved.",
                type: "success",
                timeout: 4000
            }
            return { ...state, notifsToShow: [...state.notifsToShow, successNotifId], allNotifs: [...state.allNotifs, successNotif] }
    }
    return state;
}
