/** @jsxImportSource @emotion/react */
import {
    Col, Container, Row, NavItem, Nav, TabPane, TabContent,
} from "reactstrap";
import { css } from "@emotion/react";
import { ReactElement } from "react";

const FADED_BLUE = "#172245";
const DARK_BLUE = "#324f7d";
const WHITE_ISH = "#f5f5f5";

export const DataTestId = {
    TAB: "TAB",
    TAB_CONTENT: "TAB_CONTENT",
};

export type Tab = {
    title: string,
    content: ReactElement<any, any>,
}

export type TabbedContainerProps = {
    activeTab: number;
    onTabChange: (activeTab: number) => void;
    tabs: Tab[];
}

export default function TabbedContainer({ tabs, activeTab, onTabChange }: TabbedContainerProps) {
    const tabClass = (index) => `p-2 rounded-top text-center border ${activeTab === index ? "" : ""}`;
    const tabStyle = (index) => css`width:9em;${activeTab === index ? `background-color:${WHITE_ISH};color:${DARK_BLUE}` : `background-color:${FADED_BLUE};cursor:pointer`}`;
    return <Container>
        <Row className="justify-content-center">
            <Col>
                <Nav tabs>
                    {
                        tabs.map((tab, index) => <NavItem key={index} className={tabClass(index)} css={tabStyle(index)} onClick={() => onTabChange(index)} data-testid={`${DataTestId.TAB}${index}`}>
                            {tab.title}
                        </NavItem>)
                    }
                </Nav>
                <TabContent activeTab={tabs.length > 1 ? activeTab : 0}>
                    {
                        tabs.map((tab, index) => <TabPane key={index} tabId={index} data-testid={`${DataTestId.TAB_CONTENT}${index}`}>
                            {tab.content}
                        </TabPane>)
                    }
                </TabContent>
            </Col>
        </Row>
    </Container>;
}