import { Action } from '../actions';
import { LOGIN, LOGOUT, POWERBI_AUTH_GET, POWERBI_GET } from '../actions/Auth/action-types';
import { GET as GET_SIGNAL, LIST as LIST_SIGNAL } from '../actions/Signal/action-types';
import { ANSWER, LIST as LIST_QUESTIONS } from '../actions/Question/action-types';

export interface User {
    sub: string;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    email: string;
}

export interface AuthState {
    token?: string;
    user?: User;
    isLoading: boolean;
    logoutRequested: boolean;
    authorizedToSeePowerBI?: boolean;
    powerbiEmbedInfo?: {
        reportConfig: Array<{
            reportId: string;
            embedUrl: string;
        }>
        accessToken: string;
    } | null;
    isPowerbiLoading: boolean
}
const defaultState = {
    isLoading: false,
    logoutRequested: false,
    isPowerbiLoading: false
}
export default function Reducer(state: AuthState = defaultState, action: Action): AuthState {
    switch (action.type) {
        case LOGIN.SUCCESS:
            return { ...state, ...action.payload.data }
        case LOGOUT.REQUEST:
            return { ...state, user: undefined, token: undefined, logoutRequested: true };
        case LOGOUT.SUCCESS:
            return defaultState;
        case LOGOUT.ERROR:
            return { ...state, logoutRequested: false };
        case GET_SIGNAL.ERROR:
        case LIST_SIGNAL.ERROR:
        case LIST_QUESTIONS.ERROR:
        case ANSWER.ERROR:
            if (action["error"]["response"]["status"] === 401) return { ...state, logoutRequested: true };
            break;
        case POWERBI_GET.LOADING:
            return { ...state, isPowerbiLoading: true };
        case POWERBI_GET.SUCCESS:
            return { ...state, powerbiEmbedInfo: action.payload.data, isPowerbiLoading: false };
        case POWERBI_GET.ERROR:
            return { ...state, powerbiEmbedInfo: null, isPowerbiLoading: false };
        case POWERBI_AUTH_GET.LOADING:
            //Do nothing
            return state;
        case POWERBI_AUTH_GET.SUCCESS:
            return { ...state, authorizedToSeePowerBI: action.payload.data, isPowerbiLoading: false };
        case POWERBI_AUTH_GET.ERROR:
            return { ...state, authorizedToSeePowerBI: false, isPowerbiLoading: false };
    }
    return state;
}
