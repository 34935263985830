export function getTheme(theme) {
  return {
...theme,
borderRadius: 0,
colors: {
...theme.colors,

primary: '#1c5caf',
primary75: '#144686',
primary50: '#133969',
primary25: '#0e2c53',

danger: '#DE350B',
dangerLight: '#FFBDAD',

neutral0: '#0b1228',
// neutral5: '#fffeff',
neutral10: '#34495e',
// neutral20: '#fffeff',
// neutral30: '#fffeff',
// neutral40: '#fffeff',
// neutral50: '#fffeff',
// neutral60: '#fffeff',
// neutral70: '#fffeff',
neutral80: '#fff',
// neutral90: '#0b1228',
},

}}
