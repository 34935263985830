import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer, { RootState } from './reducers';
import axiosMiddleware from 'redux-axios-middleware';
import apiClient from '../constants/apiClient';
import {ROOT_URL} from '../constants/urls';

function configureStore(initialState?: RootState) {
    const logger = createLogger();
    apiClient.defaults.baseURL = ROOT_URL;
    const middleware = applyMiddleware(thunk, axiosMiddleware(apiClient), logger);

    const createStoreWithMiddleware = compose(
        middleware
    );

    return createStoreWithMiddleware(createStore)(rootReducer, initialState);
}

const store = configureStore();

export default store;