import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

interface Params {
    children: any;
    className?: string;
    css: object;
    onClick: any;
}

export function Warning({ children, className, ...props }: Params) {
    return (
        <div className={'p-3 bg-light border border-warning ' + className} {...props} >{children}</div>
    )
}

export function Danger({ children, className, ...props }: Params) {
    return (
        <div className={'p-3 bg-light border border-danger ' + className} {...props} >{children}</div>
    )
}

export function Text({ children, className, ...props }: Params) {
    return (
        <div className={'p-3 bg-light ' + className} {...props} >{children}</div>
    )
}

export interface ParameterizedTextProps { text: string, parameters: { [key: string]: string | number } }
export const ParameterizedText = ({ text, parameters }: ParameterizedTextProps) => {
    const formatters = {
        bold: (word: string) => <b>{word}</b>,
        percentage: (word: string) => <b>{`${(Number(word) * 100).toFixed(1).replace('.0', '')}%`}</b>,
        break: () => <br />
    }
    /**
     * The regular expression pattern {([^}]+)} matches any string that is enclosed in curly braces { } and captures the text inside the braces.
            */
    const words: string[] = text.match(/\{([^}]+)\}/g) || [];

    /**
     * Returns an object in structure where keys are raw blocks that contains stylies and word,
     * values are the formatted words
     */
    const formattedWords: { [x: string]: EmotionJSX.Element | string } = words.reduce((acc, curr) => {
        /**
            * The content of the blocks of { } will be splitted by comma ',' into parts
            * Parts can be word or style
            */
        const parts = curr.replace("{", "").replace("}", "").trim().split(",").map(x => x.trim());
        /**
         * Get word - styles pair. Per word there may be couple of style needs to be apply to the word.
         * styles starts with :, @example :bold, :percentage
         */
        const { word, styles } = parts.reduce((acc, curr) =>
            curr.includes(":")
                ? { ...acc, styles: [...(acc.styles || []), curr.slice(1)] }
                : { ...acc, word: `${parameters[curr]}` },
            { word: "" } as { word: string, styles?: string[] });

        /**
         * After obtaining word and style. Apply stylies to the word.
         */
        const formattedWord = styles ? styles.reverse().reduce((acc, curr) => {
            const formatter = formatters[curr];
            return formatter ? formatter(acc) : acc
        }, word) : word;
        return { ...acc, [curr]: formattedWord };
    }, {});

    const parts = words.reduce((acc, curr) => {
        const remaining = acc[acc.length - 1];
        const [pre, post] = remaining.split(curr);
        return [...acc.slice(0, acc.length - 1), pre, post];
    }, [text]);

    const Item = ({ children }) => <>{children}</>
    return <>
        <>{parts[0]}</>
        {words.map((word, index) => (
            <Item key={index}>
                <>{formattedWords[word]}</>
                <>{parts[index + 1]}</>
            </Item>
        ))}
    </>;
}
