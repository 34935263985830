import { Action } from '../actions';
import { LIST } from '../actions/Answer/action-types';
import { ANSWER } from '../actions/Question/action-types';
import { NAVIGATION } from '../actions/Navigation/action-types';
import { AnsweredQuestion } from '../models';


export interface AnswerState {
    isLoading: boolean;
    answers?: {
        [date: string]: AnsweredQuestion[]
    };
}

const defaultState: AnswerState = {
    isLoading: false
};

const reducer = (state: AnswerState = defaultState, action: Action): AnswerState => {
    switch (action.type) {
        case LIST.LOADING:
            return { ...state, isLoading: true };
        case LIST.ERROR:
            return { ...state, isLoading: false, answers: {} };
        case LIST.SUCCESS:
            return {
                ...state,
                isLoading: false,
                answers: action.payload.data
            };
        case NAVIGATION.NAVIGATE:
            return { ...state, answers: action.payload.data.path === "/" ? undefined : state.answers }
        case ANSWER.SUCCESS:
            return {
                ...state,
                answers: undefined
            }
        default:
            return state;
    }
}

export default reducer;