import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import authActions from '../store/actions/Auth/actions';
import { Buffer } from "buffer";
import store from '../store';
import navigationActions from '../store/actions/Navigation/actions';
import { constants } from "./utils";

function parseJwt(token: string) {
    const base64Payload = token.split('.')[1];
    const payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
}

const AuthService = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const { logoutRequested, token } = store.getState().auth;
        if (logoutRequested) {
            localStorage.removeItem(constants.TOKEN);
            dispatch(authActions.logoutSuccess());
            dispatch(navigationActions.navigate("/login"));
        } else {
            const localStorageToken = localStorage.getItem(constants.TOKEN);
            if (token && !localStorageToken) {
                localStorage.setItem(constants.TOKEN, token);
            } else if (!token && localStorageToken) {
                const user = parseJwt(localStorageToken);
                dispatch(authActions.loginSuccess(user, localStorageToken));
            }
        }
    });
}

export default AuthService;