/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Table } from "reactstrap";
import { css } from '@emotion/react';
import signalActions from "../../../store/actions/Signal/actions";
import { Signal } from "../../../store/models";
import { RootState } from "../../../store/reducers";
import TypeBadge from "../../Helper/TypeBadge";
import StatusBadge from "../../Helper/StatusBadge"
import navigationActions from "../../../store/actions/Navigation/actions";
import { SignalState } from "../../../store/reducers/signal";
import store from "../../../store";
import { dashIfEmpty, getAbsenceDuration, getSignalCreateDate } from "../../../store/helpers";
import { formatAbsenceDuration } from "../../../utils/text-utils";
import { DATE_FORMAT_WITHOUT_CLOCK, format } from "../../../utils/date-utils";
import { LoadingOverlay } from "../../Helper/Loading";
import Pagination from "../../Helper/Pagination";
import authActions from "../../../store/actions/Auth/actions";

const signalBlockStyles = css`
    height: 45px;
    cursor: pointer;
`;

const signalsHeaderBorderStyles = css`
    height: 45px;
    border-bottom: 1pt solid white;
`;

function SignalBlockHeader() {
    return (
        <React.Fragment>
            <tr css={signalsHeaderBorderStyles}>
                <th css={css`width: 150px; position:relative;`}>DOCENT</th>
                <th css={css`width: 150px; position:relative; text-align: center;`}>TYPE</th>
                <th css={css`width: 150px; position:relative; text-align: center;`}>STATUS</th>
                <th css={css`width: 150px; position:relative;text-align: center;`}>SIGNAAL DATUM</th>
                <th css={css`width: 100px; position:relative;text-align: center;`}>VERZUIM ID</th>
                <th css={css`position:relative;text-align: center;`}>VERZUIMDUUR</th>
                <th css={css`position:relative;text-align: center;`}>VERWACHTING</th>
                <th css={css`position:relative;text-align: center;`}>INTERVENTIE</th>
            </tr>
        </React.Fragment>
    )
}

function SignalBlockContent({ item, onSelect }: SignalBlockContentProps) {
    return (
        <tr onClick={() => onSelect(item.id)} css={signalBlockStyles}>
            <td>{item.employee.context.name ? item.employee.context.name : "-"}</td>
            <td><TypeBadge type={item.type} /></td>
            <td><StatusBadge status={item.status} /></td>
            <td className="text-center">{format(getSignalCreateDate(item), DATE_FORMAT_WITHOUT_CLOCK)}</td>
            <td className="text-center">{item.absence_id}</td>
            <td className="text-center">{dashIfEmpty(getAbsenceDuration(item), value => formatAbsenceDuration(value))}</td>
            <td className="text-center">{item.expected_leave_time}</td>
            <td className="text-center">{item.intervention}</td>
        </tr>
    )
}
interface SignalBlockContentProps {
    item: Signal,
    onSelect: (signalId: string) => void;
}

export default function Inbox() {
    const { isItemsLoading, items, pagination } = useSelector<RootState, SignalState>(state => state.signal);
    const dispatch = useDispatch();

    useEffect(() => {
        const { items, isItemsLoading } = store.getState().signal;
        if (!items && !isItemsLoading) {
            const offset = (pagination.currentPage - 1) * pagination.limit;
            dispatch(signalActions.list(offset, pagination.limit));
        }
        //authorizedToSeePowerBI
        const { authorizedToSeePowerBI } = store.getState().auth;
        if (authorizedToSeePowerBI === undefined) {
            dispatch(authActions.getIfAuthorizedToSeePowerBI());
        }
    });

    const onSelect = (signalId: string) => {
        dispatch(navigationActions.navigate(`/signals/${signalId}`));
    }

    const onPageChange = (pageNumber: number) => {
        dispatch(signalActions.changePage(pageNumber));
    }

    const onLimitChange = (limit: number) => {
        dispatch(signalActions.changeLimit(limit));
    }

    return (
        <Container>
            <Row>
                <Col>
                    {
                        isItemsLoading && <LoadingOverlay/>
                    }
                    <div className="bg-panel rounded mt-2 p-3 pt-0 pb-0">
                        <Table responsive css={css`padding:1em;overflow: hidden; vertical-align: middle !important; tbody>tr:hover{background-color:#96b9d4;};`} hover >
                            <thead>
                                <SignalBlockHeader />
                            </thead>
                            <tbody>
                                {
                                    !isItemsLoading && (!items || items.length === 0) &&
                                    <tr className="text-center">
                                        <td colSpan={8}>No Value</td>
                                    </tr>
                                }
                                {
                                    items && items.map((item, index) => <SignalBlockContent key={index} item={item} onSelect={onSelect} />)
                                }
                            </tbody>
                        </Table>
                    </div>
                    {items && <Row className="align-items-center p-4">
                        <Col>
                            <Pagination
                                totalRecords={pagination.totalPages}
                                limit={pagination.limit}
                                currentPage={pagination.currentPage}
                                pageNeighbours={1}
                                onPageChange={onPageChange}
                            />
                        </Col>
                        <Col className="text-center">
                            {Math.min(items.length, pagination.limit)} van {pagination.totalPages} resultaten
                        </Col>
                        <Col>
                            <div className="btn-group-sm" css={css`text-align:right;`} role="group" aria-label="limit">
                                {[12, 25, 50, 100].map(i => {
                                    return (<button key={i} type="button" onClick={() => onLimitChange(i)} className={'btn btn-outline-secondary ' + (i === pagination.limit ? 'active' : '')}>{i}</button>)
                                })}
                            </div>
                        </Col>
                    </Row>}
                </Col>
            </Row>
        </Container>
    )
}