import { defineAction } from 'redux-define';
import { TheApp, LOADING, ERROR, SUCCESS, REQUEST } from '../../../constants/stateConstants';
import { User } from '../../reducers/auth';
import { ReducerAction } from '../reducer-action';

export const AUTH = defineAction('AUTH', [], TheApp);
export const LOGIN = defineAction('LOGIN', [LOADING, ERROR, SUCCESS], AUTH);
export const LOGOUT = defineAction('LOGOUT', [LOADING, ERROR, SUCCESS, REQUEST], AUTH);
export const POWERBI_GET = defineAction('POWERBI_GET', [LOADING, ERROR, SUCCESS], AUTH);
export const POWERBI_AUTH_GET = defineAction('POWERBI_AUTH_GET', [LOADING, ERROR, SUCCESS], AUTH);

export type LoginLoadingAuthAction = ReducerAction<typeof LOGIN.LOADING>;
export type LoginErrorAuthAction = ReducerAction<typeof LOGIN.ERROR, { message: string }>;
export type LoginSuccessAuthAction = ReducerAction<typeof LOGIN.SUCCESS, { user: User, token: string }>;
export type LoginAuthAction = LoginLoadingAuthAction | LoginErrorAuthAction | LoginSuccessAuthAction;

export type LogoutRequestAuthAction = ReducerAction<typeof LOGOUT.REQUEST>;
export type LogoutLoadingAuthAction = ReducerAction<typeof LOGOUT.LOADING>;
export type LogoutErrorAuthAction = ReducerAction<typeof LOGOUT.ERROR>;
export type LogoutSuccessAuthAction = ReducerAction<typeof LOGOUT.SUCCESS>;
export type LogoutAuthAction = LogoutLoadingAuthAction | LogoutErrorAuthAction | LogoutSuccessAuthAction | LogoutRequestAuthAction;

export type PowerBIGetLoadingAction = ReducerAction<typeof POWERBI_GET.LOADING>;
export type PowerBIGetSuccessAction = ReducerAction<typeof POWERBI_GET.SUCCESS, { reportConfig: Array<{ reportId: string; embedUrl: string; }>; accessToken: string; }>;
export type PowerBIGetErrorAction = ReducerAction<typeof POWERBI_GET.ERROR>;
export type PowerBIGetAction = PowerBIGetLoadingAction | PowerBIGetSuccessAction | PowerBIGetErrorAction;

export type PowerBIAuthGetLoadingAction = ReducerAction<typeof POWERBI_AUTH_GET.LOADING>;
export type PowerBIAuthGetSuccessAction = ReducerAction<typeof POWERBI_AUTH_GET.SUCCESS, boolean>;
export type PowerBIAuthGetErrorAction = ReducerAction<typeof POWERBI_AUTH_GET.ERROR>;
export type PowerBIAuthGetAction = PowerBIAuthGetLoadingAction | PowerBIAuthGetSuccessAction | PowerBIAuthGetErrorAction;

export type AuthAction = LoginAuthAction | LogoutAuthAction | PowerBIGetAction | PowerBIAuthGetAction;