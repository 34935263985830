import { defineAction } from 'redux-define';
import { DISMISS, TheApp, WARNING,SHOWN, ERROR } from '../../../constants/stateConstants';
import { Notification } from '../../reducers/notifs';
import { ReducerAction } from '../reducer-action';

export const NOTIFS = defineAction('NOTIFS', [WARNING,ERROR,DISMISS,SHOWN], TheApp);

export type NotifsWarningAction = ReducerAction<typeof NOTIFS.WARNING, Notification>;
export type NotifsErrorAction = ReducerAction<typeof NOTIFS.ERROR, Notification>;
export type NotifsDismissAction = ReducerAction<typeof NOTIFS.DISMISS, {notificationId: string}>;
export type NotifsShownAction = ReducerAction<typeof NOTIFS.SHOWN, {notificationId: string}>;

export type NotifsAction = NotifsWarningAction | NotifsDismissAction | NotifsShownAction;