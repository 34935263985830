import { Feedback } from '../../models';
import { LIST, ANSWER } from './action-types';

const endpoint = "feedback";

const signalActions = {
    list: (signalId: string) => {
        return {
            types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
            payload: {
                request: {
                    method: 'GET',
                    url: `${endpoint}/${signalId}`,
                }
            }
        }
    },
    answer: (feedbacks: Feedback[], signalId: string) => {
        return {
            types: [ANSWER.LOADING, ANSWER.SUCCESS, ANSWER.ERROR],
            payload: {
                request: {
                    method: 'POST',
                    url: `${endpoint}/${signalId}`,
                    data: feedbacks
                }
            }
        }
    }
}

export default signalActions
