/** @jsxImportSource @emotion/react */
import { Fragment } from "react";

interface PaginationProps {
    totalRecords: number,
    limit: number,
    pageNeighbours: number,
    currentPage: number
    onPageChange: (pageNumber: number) => void
}

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from: number, to: number, step = 1): number[] => {
    let i = from;
    const range: number[] = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

const Pagination = ({ totalRecords, onPageChange, limit, currentPage, pageNeighbours }: PaginationProps) => {

    const totalPages = Math.ceil(totalRecords / limit);

    const gotoPage = page => {
        const currentPage = Math.max(0, Math.min(page, totalPages));
        onPageChange(currentPage);
    };

    const handleClick = (page, evt) => {
        evt.preventDefault();
        gotoPage(page);
    };

    const handleMoveLeft = evt => {
        evt.preventDefault();
        gotoPage(currentPage - (pageNeighbours * 2 - 1));
    };

    const handleMoveRight = evt => {
        evt.preventDefault();
        gotoPage(currentPage + (pageNeighbours * 2 - 1));
    };

    const fetchPageNumbers = () => {
        const totalNumbers = pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            let pages: Array<number | "LEFT" | "RIGHT"> = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };


    const pages = fetchPageNumbers();

    return (
        <Fragment>
            <nav aria-label="Countries Pagination">
                <ul className="pagination pagination-sm mb-0">
                    {pages.map((page, index) => {
                        if (page === LEFT_PAGE)
                            return (
                                <li key={index} className="page-item">
                                    <button type="button"
                                        className="btn btn-outline-secondary"
                                        aria-label="Previous"
                                        onClick={handleMoveLeft}
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                        <span>Previous</span>
                                    </button>
                                </li>
                            );

                        if (page === RIGHT_PAGE)
                            return (
                                <li key={index} className="page-item">
                                    <button type="button"
                                        className="btn btn-outline-secondary"
                                        aria-label="Next"
                                        onClick={handleMoveRight}
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                        <span>Next</span>
                                    </button>
                                </li>
                            );

                        return (
                            <li
                                key={index}
                                className={`page-item ${currentPage === page ? " active" : ""
                                    }`}
                            >
                                <button type="button" onClick={e => handleClick(page, e)} className={'btn btn-outline-secondary ' + (currentPage === page ? 'active' : '')}>{page}</button>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </Fragment>
    );

}



export default Pagination;
