/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Row } from "reactstrap";

import AssistantPanel from "./AssistantPanels"
import signalActions from "../../../store/actions/Signal/actions";
import { RootState } from "../../../store/reducers";
import { SignalState } from "../../../store/reducers/signal";
import { useEffect } from "react";
import store from "../../../store";

import navigationActions from '../../../store/actions/Navigation/actions';
import { Loading } from '../../Helper/Loading';
import LeftsidePanel from './LeftsidePanels';
import { HomeIcon } from '../../Helper/Icons';

const inboxButtonStyles = css`
    background: none!important;
    border: none!important;
    padding: 0!important;
    color: white!important;
    text-decoration: underline!important;
`;

const minWidth = css`min-width: 54em`;

export default function SignalHandlingComponent() {
  const params = useParams();
  const { current: signal } = useSelector<RootState, SignalState>(state => state.signal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (signal === undefined && !store.getState().signal.isCurrentLoading) {
      dispatch(signalActions.get(params.signalId!));
    }
  });

  const onClickInbox = () => {
    dispatch(navigationActions.navigate("/"));
  }
  return <Container className="pt-2" >
    <Breadcrumb>
      <BreadcrumbItem><Button css={inboxButtonStyles} onClick={onClickInbox}><HomeIcon /></Button></BreadcrumbItem>
      <BreadcrumbItem active className='mt-1'>{params.signalId} </BreadcrumbItem>
    </Breadcrumb>
    {
      signal
        ? <Row css={css`justify-content: center;`}>
          <Col md="6" css={minWidth}>
            <LeftsidePanel signal={signal} />
          </Col>
          <Col md="6" css={minWidth}>
            <AssistantPanel signal={signal} />
          </Col>
        </Row>
        : <Loading />
    }
  </Container>
}