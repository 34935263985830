import { Col, Row } from "reactstrap";
import { getSignalCreateDate, getWorkCapacityPercentage } from "../../../store/helpers";
import { Signal } from "../../../store/models";
import { DATE_FORMAT_WITHOUT_CLOCK, format } from "../../../utils/date-utils";
import StatusBadge from "../../Helper/StatusBadge";
import TypeBadge from "../../Helper/TypeBadge";

export interface MetaProps {
    signal: Signal;
}

export const DataTestId = {
    META: "META"
}

export default function Meta({ signal }: MetaProps) {
    return (
        <Row className="py-2 rounded" data-testid={DataTestId.META}>
            <Col sm="2" className="py-2">
                <div className="pb-3 font-weight-bolder">Docent</div>
                {signal.employee.context?.name}
            </Col>
            <Col sm="2" className="py-2 text-center">
                <div className="pb-3 font-weight-bolder">Type</div>
                <TypeBadge type={signal.type} />
            </Col>
            <Col sm="2" className="py-2 text-center">
                <div className="pb-3 font-weight-bolder">Status</div>
                <StatusBadge status={signal.status} />
            </Col>
            <Col sm="2" className="py-2 text-center">
                <div className="pb-3 font-weight-bolder">Signaaldatum</div>
                {format(getSignalCreateDate(signal), DATE_FORMAT_WITHOUT_CLOCK)}
            </Col>
            <Col sm="2" className="py-2 text-center">
                <div className="pb-3 font-weight-bolder">Signaal ID</div>
                {signal.absence_id}
            </Col>
            <Col sm="2" className="py-2 text-center">
                <div className="pb-3 font-weight-bolder">Arbeidsgeschikt</div>
                {getWorkCapacityPercentage(signal)} %
            </Col>
        </Row>
    );
}
