import { ANSWER, LIST } from '../actions/Question/action-types';
import { LIST as LIST_ANSWERS } from '../actions/Answer/action-types';
import { Question } from '../models';
import { SIGNALS } from '../actions/Signal/action-types';
import { Action } from '../actions';
import { LOGOUT } from '../actions/Auth/action-types';
import { NAVIGATION } from '../actions/Navigation/action-types';


export interface QuestionState {
    isQuestionsLoading: boolean;
    isAnsweringInprogress: boolean;
    questions?: Question[];
}

const defaultState: QuestionState = {
    isQuestionsLoading: false,
    isAnsweringInprogress: false
};

const reducer = (state: QuestionState = defaultState, action: Action): QuestionState => {
    switch (action.type) {
        case SIGNALS.UNSELECT:
            return { ...state, questions: undefined, isQuestionsLoading: false };
        case LIST.LOADING:
            return { ...state, isQuestionsLoading: true };
        case ANSWER.LOADING:
            return { ...state, isAnsweringInprogress: true };
        case LIST.ERROR:
            return { ...state, isQuestionsLoading: false, questions: [] };
        case ANSWER.ERROR:
            return { ...state, isAnsweringInprogress: false };
        case LIST.SUCCESS:
            return {
                ...state,
                isQuestionsLoading: false,
                questions: action.payload.data
            };
        case ANSWER.SUCCESS:
            return {
                ...state,
                isAnsweringInprogress: false,
            }
        case LIST_ANSWERS.SUCCESS:
            return {
                ...state,
                isQuestionsLoading: false
            }
        case LOGOUT.SUCCESS:
            return defaultState;
        case NAVIGATION.NAVIGATE:
            return { ...state, questions: action.payload.data.path === "/" ? undefined : state.questions }
        default:
            return state;
    }
}

export default reducer;