import { Badge } from "reactstrap";
import { SignalStatus } from "../../store/models";

const englishToDutchStatusMaper = (status: string) => {
  switch (status) {
    case "NEW":
      return "NIEUW";
    case "PASSED_DEADLINE":
      return "OVER TIJD";
    case "PENDING":
      return "LOPEND VERZUIM";
    case "UPDATE":
      return "UPDATE";
    case "CLOSED":
      return "GESLOTEN";
    case "CONTACT_TEACHER":
      return "CONTACT DOCENT";
    case "INTERVENTION":
      return "INTERVENTIE";
    default:
      return "-";
  }
}

function StatusBadge({ status }: StatusBadgeProps) {
  const classname = `signal-status ${(status).toLowerCase()} py-2 px-1 text-uppercase`;
  return (
    <Badge className={classname}>{englishToDutchStatusMaper(status)}</Badge>
  )
}
interface StatusBadgeProps {
  status: SignalStatus;
}

export default StatusBadge;
