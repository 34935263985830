import { Signal } from "../../../../store/models";
import { ParameterizedText } from "../Helpers";

/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import questionActions from "../../../../store/actions/Question/actions";
import answerActions from "../../../../store/actions/Answer/actions";
import { QuestionState } from '../../../../store/reducers/question';
import { NavigationState } from '../../../../store/reducers/navigation';
import { AnswerState } from '../../../../store/reducers/answer';
import createQuestionsPane from "./Questions";
import createAnsweredQuestionsPane from "./AnsweredQuestions";
import AssistantAvatar, { Pane } from "../../../AssistantAvatar";
import { DATE_FORMAT_WITHOUT_CLOCK, format } from "../../../../utils/date-utils";

interface AssistantPanelProps {
    signal: Signal
}


export default function AssistantPanel({ signal }: AssistantPanelProps) {
    const text = <ParameterizedText {...signal.assistant_message} />;
    const dispatch = useDispatch();
    const { isQuestionsLoading, isAnsweringInprogress, questions }: QuestionState = useSelector<RootState, QuestionState>(state => state.question);
    const { isLoading: isAnswersLoading, answers: answeredQuestions }: AnswerState = useSelector<RootState, AnswerState>(state => state.answer);
    const { path } = useSelector<RootState, NavigationState>(state => state.navigation);

    const [selectedPane, setSelectedPane] = useState(0);


    useEffect(() => {
        if ((!questions && !isQuestionsLoading) && ["NEW", "UPDATE", "PASSED_DEADLINE"].includes(signal.status as string) && path.startsWith("/signals/")) {
            dispatch(questionActions.list(signal.id));
        }
        if ((!answeredQuestions && !isAnswersLoading) && path.startsWith("/signals/")) {
            dispatch(answerActions.list(signal.id));
        }
    });

    const panes = [] as Pane[];
    const isLoading = isQuestionsLoading || isAnsweringInprogress;
    switch (signal.status) {
        case 'NEW':
        case 'UPDATE':
        case 'PASSED_DEADLINE':
            const questionsPane: Pane = createQuestionsPane("Huidig signaal", signal.id, questions);
            panes.push(questionsPane);
    }
    if (answeredQuestions) {
        const sorted_dates = Object.keys(answeredQuestions).map(key => [new Date(key), key]).sort((date1, date2) => date1[0] < date2[0] ? 1 : -1);
        for (const [date, dateInText] of sorted_dates) {
            const answeredQuestionsSub = answeredQuestions[dateInText as string];
            const pane: Pane = createAnsweredQuestionsPane(format(new Date(date), DATE_FORMAT_WITHOUT_CLOCK), answeredQuestionsSub);
            panes.push(pane);
        }
    }
    return <AssistantAvatar text={text} panes={panes} isLoading={isLoading} selectedPane={selectedPane} onPaneChange={setSelectedPane} />
}